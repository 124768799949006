@import "../../main";

.heading2 {
  @media only screen and (max-width: 700px) {
    font-size: $font-size-regular;
  }
  @media only screen and (max-width: 450px) {
    width: 100%;

    text-align: left;
  }

  &--danger {
    color: $color-danger;
  }
  &--light {
    color: $color-grey;
  }
}
