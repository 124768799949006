@import "../../main";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .heading1 {
    margin-bottom: 3rem;

    text-align: center;
    max-width: 75rem;
  }

  & .paragraph {
    width: 70rem;
    max-width: 100%;

    & a {
      color: $color-primary;
      text-decoration: none;
    }
  }

  &__body {
    margin-top: 2rem;
    width: 70rem;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    & .heading3 {
      margin-top: 5rem;
      margin-bottom: 3rem;
    }
  }

  &__form {
    width: 100%;
  }

  &__inputWrapper {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    & label {
      font-size: $font-size-regular;

      & span {
        color: red;
      }
    }

    & input, textarea {
      width: 100%;
      padding: 1rem 1.5rem;
      margin-top: 0.5rem;
      border-radius: 0.2rem;
      border: none;
      outline: none;
      box-shadow: 0 4px 4px rgba($color-black, 0.15),
        0 0 4px rgba($color-black, 0.15);

      font-family: 'Inter', sans-serif;
      font-size: $font-size-regular;

      &.invalid {
        box-shadow: 0 0 4px 1px red;
      }
    }

    & textarea {
      resize: none;
      height: 15rem;
    }
  }
}
