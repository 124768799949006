.header {
  padding: 5rem 25rem 0 25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media only screen and (max-width: 1800px) {
    padding: 5rem 15rem 0 15rem;
  }
  @media only screen and (max-width: 1400px) {
    padding: 5rem 10rem 0 10rem;
  }
  @media only screen and (max-width: 650px) {
    padding: 3rem 5rem 0 5rem;
  }
  @media only screen and (max-width: 520px) {
    padding: 3rem 3rem 0 3rem;
  }
}
