@import '../../main';

.footer {
  padding: 1rem;

  text-align: center;
  color: $color-grey;
  font-size: $font-size-tiny;
  font-weight: $weight-regular;

  & a {
    text-decoration: none;
    color: inherit;
  }
}
