@import "../../main";

.cta {
  padding: 1.5rem 3rem;
  max-width: 100%;
  border-radius: 0.5rem;
  background-color: $color-action;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba($color-black, .5);

  color: $color-black;
  font-weight: $weight-bold;
  font-size: $font-size-medium;

  @media only screen and (max-width: 500px) {
    font-size: $font-size-regular;
  }
}
