@import "../../main";

.bulletText {
  width: 100%;
  max-width: 50rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 750px) {
    padding: 1.5rem 0;
  }
  // @media only screen and (max-width: 450px) {
  //   flex-direction: column;
  // }

  &--alignTop {
    align-items: flex-start;
  }

  & .heading3 {
    margin-bottom: 1rem;
  }

  & img {
    margin-right: 1rem;

    // @media only screen and (max-width: 450px) {
    //   margin-right: 0;
    // }
    @media only screen and (max-width: 450px) {
      width: 3rem;
    }
  }
}
