@import "../../main";

.introduction {
  &__row {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }

    &--1 {
      & img {
        margin-left: 4rem;
    
        @media only screen and (max-width: 1100px) {
          margin-left: 0;
          margin-top: 3rem;
        }
      }
    }
    &--2 {
      & img {
        margin-right: 4rem;
    
        @media only screen and (max-width: 1100px) {
          margin-right: 0;
          margin-bottom: 3rem;
        }
      }
    }
  }

  & img {
    width: 45%;
    object-fit: contain;
    
    @media only screen and (max-width: 1100px) {
      width: 60%;
    }
    @media only screen and (max-width: 900px) {
      width: 80%;
    }
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }

  &__content {
    width: 50%;
    
    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }

  &__quote {
    max-width: 100rem;
    padding: 8rem 0;

    text-align: center;
    color: $color-danger;
    font-size: $font-size-big;
    font-weight: $weight-semibold;
    font-style: italic;
    
    @media only screen and (max-width: 1100px) {
      font-size: $font-size-medium;
    }
  }
}
