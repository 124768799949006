@import '../../main';

.popup {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color-grey, .4);

  position: fixed;
  top: 0;
  left: 0;

  &__content {
    padding: 4rem;
    background-color: $color-white;
    border-radius: 1rem;

    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 45%;
  }
}
