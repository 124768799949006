@import "../../main";

.heading1 {
  text-align: center;
  font-weight: $weight-semibold;
  font-size: $font-size-large;

  @media only screen and (max-width: 700px) {
    font-size: $font-size-big;
  }
  @media only screen and (max-width: 450px) {
    width: 100%;
    text-align: left;
    font-size: $font-size-medium;
  }

  &--center {
    text-align: center !important;
  }
  &--primary {
    color: $color-primary;
  }
  &--danger {
    color: $color-danger;
  }
  &--white {
    color: $color-white;
  }
}
