@import "../../main";

.heading3 {
  @media only screen and (max-width: 700px) {
    font-size: $font-size-regular;
  }

  &--center {
    text-align: center;
  }
  &--danger {
    color: $color-danger;
  }
  &--light {
    color: $color-grey;
  }
}
