.offer {
  width: 100%;

  & .heading1 {
    margin-bottom: 3rem;
  }

  &__bulletText__column2 {
    grid-column: 2;

    @media only screen and (max-width: 1220px) {
      grid-column: 1 / span 2;
      align-self: center;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .bulletText {
      max-width: 60rem;
    }
  }
  &__secondary {
    width: 100%;
    margin-bottom: 3rem;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;

    @media only screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
    @media only screen and (max-width: 920px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
