.negativeMotivation {
  & .heading1 {
    margin-bottom: 3rem;
  }

  &__content {
    display: flex;

    @media only screen and (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }

    &__left, &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @media only screen and (max-width: 920px) {
        align-items: center;
      }
    }
  }
}
