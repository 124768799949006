@import '../../main';

.paragraph {
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 1600px) {
    font-size: $font-size-small;
  }

  &--center {
    text-align: center;
  }
  &--small {
    font-size: $font-size-small;

    @media only screen and (max-width: 1600px) {
      font-size: $font-size-tiny;
    }
  }

  & span {
    font-weight: $weight-bold;
  }
}
