.section {
  padding: 10rem 25rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1800px) {
    padding: 10rem 15rem;
  }
  @media only screen and (max-width: 1400px) {
    padding: 10rem;
  }
  @media only screen and (max-width: 1100px) {
    padding: 5rem 10rem;
  }
  @media only screen and (max-width: 800px) {
    padding: 5rem;
  }
  @media only screen and (max-width: 500px) {
    padding: 3rem;
  }
}
