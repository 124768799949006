.case {
  & .heading1 {
    margin-bottom: 3rem;
  }

  & img {
    width: 30%;
    min-width: 35rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: 500px) {
      min-width: unset;
      width: 100%;
    }
  }

  & .paragraph {
    width: 100%;
    max-width: 60rem;

    @media only screen and (max-width: 500px) {
      text-align: left;
    }
  }
}
