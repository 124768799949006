@import "../../main";

.programmingValue {
  width: 100rem;
  max-width: 100%;

  text-align: center;
  font-size: $font-size-big;

  @media only screen and (max-width: 1100px) {
    font-size: $font-size-medium;
  }
  @media only screen and (max-width: 700px) {
    text-align: left;
    font-size: $font-size-regular;
  }

  & strong {
    color: $color-primary;
    font-weight: $weight-bold;
  }
}
