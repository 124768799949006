// Colors
$color-primary: #E7852A;
$color-danger: #EC2929;
$color-action: #F8BB1F;

$color-background-light: #EEF2F4;
$color-background: #78B2CF;

$color-black: #373737;
$color-grey: #A7A7A7;
$color-white: #FFFFFF;

// Font sizes
$font-size-tiny: 1.6rem;
$font-size-small: 1.8rem;
$font-size-regular: 2.2rem;
$font-size-medium: 2.8rem;
$font-size-big: 3.7rem;
$font-size-large: 4.6rem;

// Font weights
$weight-regular: 400;
$weight-semibold: 600;
$weight-bold: 700;
$weight-extrabold: 800;
